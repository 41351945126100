import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import BeritaTerkiniSide from '../components/TerkiniSide'

import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TumblrShareButton,
  TumblrIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";

export const BlogPostTemplate = ({
  content,
  alamatutama = 'https://tatiantel2.com',
  slug,
  date,
  kategori,
  featuredimage,
  contentComponent,
  excerpt,
  caption,
  tags,
  title,
  helmet,
}) => {

  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
      
      <div className="col-container">
      <div className="terkini-list">
          <div className="column is-10 is-offset-1">
          <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
          <div style={{ marginBottom: 30, display: 'flex' }}>
            <span style={{ fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto' }}>{date}&nbsp;&nbsp;|&nbsp;&nbsp;{kategori[0]}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <img src="https://views.show/svg?key={slug}&size=16" style={{ height: 27, width: 90 }} />
          </div>
          <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
            <div>
              {featuredimage &&
                <PreviewCompatibleImage
                  imageInfo={{
                    image: featuredimage,
                    alt: title,
                  }}
                />
              }
            </div>
          </div>
            
            <p style={{ fontSize: 12, textAlign: 'center', border: 'solid 1px #000', backgroundColor: 'rgb(255, 241, 221)' }}>{caption}</p>
            <br />

            <PostContent content={content} />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>#{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            
            <br /><br />
            <div className="share-section">
              <h4 className="bagikan">Bagikan:</h4>
              <FacebookShareButton url={alamatutama + slug} quote={excerpt} className="share-button" >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <FacebookMessengerShareButton url={slug} appId="363762830961156" className="share-button" >
                <FacebookMessengerIcon size={32} round />
              </FacebookMessengerShareButton>
              <TwitterShareButton url={alamatutama + slug} title={title} className="share-button" >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <TelegramShareButton url={alamatutama + slug} title={title} className="share-button" >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
              <WhatsappShareButton url={alamatutama + slug} title={title} separator=":: " className="share-button" >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <LinkedinShareButton url={alamatutama + slug} className="share-button">
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <TumblrShareButton url={alamatutama + slug} title={title} className="share-button" >
                <TumblrIcon size={32} round />
              </TumblrShareButton>
            </div>
  

          </div>
      </div>
          <div className="terkini-side">
            <h3>Builds</h3>
            <BeritaTerkiniSide />
          </div>
      </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  contentComponent: PropTypes.func,
  featuredpost: PropTypes.bool,
  excerpt: PropTypes.string,
  caption: PropTypes.string,
  date: PropTypes.string,
  kategori: PropTypes.array,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        slug={post.fields.slug}
        date={post.frontmatter.date}
        kategori={post.frontmatter.kategori}
        excerpt={post.frontmatter.excerpt}
        caption={post.frontmatter.caption}
        featuredimage={post.frontmatter.featuredimage}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.excerpt}`}
            />
            <meta property="og:image" content={'https://dukcapil.kemendagri.web.id' + `${post.frontmatter.featuredimage}` } />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
            slug
          }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        featuredpost
        kategori
        excerpt
        caption
        featuredimage {
            childImageSharp {
              fluid(maxWidth: 250, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        title
        tags
      }
    }
  }
`
